import { FC } from "react"
import { Link } from "react-router-dom";
import { page_routes } from "../../helper/page_routes";
import AuthLayout from "../../layouts/authLayout";

/*
  * Insufficient Permission Screen
*/
const InsufficientPermissionPage:FC = () => {
    
    return (
        <AuthLayout>
            <div className="page-not-found text-center">
                <h2 className="mb-3 fw-bold fs-3">403 - Insufficient Permission</h2>
                <Link to={page_routes.dashboard} className="btn btn-lg btn-primary">Go Back</Link>
            </div>
        </AuthLayout>
    )
}
export default InsufficientPermissionPage;
